<!--  -->
<template>
  <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in componentsList" :key="index">
				<component v-if="activeIndex==index" :class="randomAni()" :is="item.component"></component>
          </div>
        </div>
          
    </div> 
</template>

<script setup lang='ts'>
 import {nextTick,ref} from 'vue'
import Swiper from 'swiper';
import companyIntroduce from '../components/companyIntroduce.vue';
import product from '../components/product.vue';
import systemList from '../components/systemList.vue'
import Bp from '../components/Bp.vue'
import Ds from '../components/Ds.vue'
import Game from '../components/Game.vue'
import Mp from '../components/Mp.vue'
import Hb from '../components/Hb.vue'
import Night from '../components/Night.vue'
import Partner from '../components/Partner.vue'
import Contact from '../components/Conact.vue'
import Face from '../components/Face.vue'
import mindAr from '../components/mindAr.vue';
import { random } from '../assets/js/utils';
import Home from '../components/Home.vue';
import { getQueryString } from '../assets/js/utils';

const componentsList = [

{
    key:'home',
    component:Home
  },
// {
//     key:'page1',
//     component:Face
//   },
{
    key:'page2',
    component:companyIntroduce
  },
{
    key:'page3',
    component:product
  },
  
 
  {
    key:'page4',
    component:systemList
  },
  {
    key:'page5',
    component:Bp
  },
  {
    key:'page6',
    component:Ds
  },
  // {
  //   key:'page7',
  //   component:Game
  // },
  {
    key:'page9',
    component:Hb
  },
  {
    key:'page8',
    component:Mp
  },
 
  {
    key:'page10',
    component:Night
  }
  ,
  {
    key:'page11',
    component:Partner
  }
  ,
  {
    key:'page12',
    component:Contact
  }
  
  
  
]
let showMindAr = getQueryString('mindAr')
if(showMindAr){
  let obj =   {
      key:'mindAr',
      component:mindAr
    }
    componentsList.unshift(obj)
}
let aniArr = ['lightSpeedIn','flipInY','bounceIn','lightSpeedIn','flipInY','zoomIn']
const randomAni = function(){
  let aniCLass = aniArr[random(0,aniArr.length-1)]
  return aniCLass
}
let activeIndex = ref<number>(0)
nextTick(function(){
  var mySwiper = new Swiper ('.swiper-container', {
        mousewheelControl : true,
	      direction: 'vertical', // 垂直切换选项
        // loop: true, // 循环模式选项
        fade:{crossFade:true},
        // onSlideChangeEnd: function(dt){
        // //Swiper初始化了
        // alert(dt.activeIndex);//提示Swiper的当前索引
        // },
        // onTouchEnd: function(dt){
        // //Swiper初始化了
       
        // },
        on: {
          transitionEnd: function(swiper:any){ 
            if(mySwiper){
              console.log(mySwiper.activeIndex);//提示Swiper的当前索引
              activeIndex.value = mySwiper.activeIndex
            } 
            
          },
          // transitionStart:function(swiper){
          //   console.log(mySwiper)
          // }
        
      }
  })
//   mySwiper.onSlideChangeEnd(function(dt){
//     alert(dt.activeIndex)
//   })
})
</script>
<style scoped lang='scss'>
.swiper-wrapper,.swiper-container{
  height: 100%;
}
.fadeInUp{
  animation: fadeInUp .3s ease-in forwards;
}
@-webkit-keyframes fadeInUp{

from {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
to {
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}
}
.zoomIn{
  animation: zoomIn .1s ease-in forwards;
}
@-webkit-keyframes zoomIn{

from {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3);
}
50% {
	opacity: 1;
}
}
.flipInY{
  animation: flipInY .3s ease-in forwards;
}
@-webkit-keyframes flipInY{

from {
	-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
	transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	opacity: 0;
}
40% {
	-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
	transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
}
60% {
	-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
	transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
	opacity: 1;
}
80% {
	-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
}
to {
	-webkit-transform: perspective(400px);
	transform: perspective(400px);
}
}

.bounceInUp{
  animation: bounceInUp .3s ease-in forwards;
}
@-webkit-keyframes bounceInUp{

from, 60%, 75%, 90%, to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
from {
	opacity: 0;
	-webkit-transform: translate3d(0, 3000px, 0);
	transform: translate3d(0, 3000px, 0);
}
60% {
	opacity: 1;
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
}
75% {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}
90% {
	-webkit-transform: translate3d(0, -5px, 0);
	transform: translate3d(0, -5px, 0);
}
to {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
}
.bounceIn{
  animation: bounceIn .5s ease-in-out forwards;
}
@-webkit-keyframes bounceIn{

from, 20%, 40%, 60%, 80%, to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
0% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3);
}
20% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
}
40% {
	-webkit-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9);
}
60% {
	opacity: 1;
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	transform: scale3d(1.03, 1.03, 1.03);
}
80% {
	-webkit-transform: scale3d(.97, .97, .97);
	transform: scale3d(.97, .97, .97);
}
to {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
}
.flip{
  animation: flip .3s ease-in forwards;
}
@-webkit-keyframes flip{

from {
	-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
	transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}
40% {
	-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
	transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}
50% {
	-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
	transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
}
80% {
	-webkit-transform: perspective(400px) scale3d(.95, .95, .95);
	transform: perspective(400px) scale3d(.95, .95, .95);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
}
to {
	-webkit-transform: perspective(400px);
	transform: perspective(400px);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
}
}
.lightSpeedIn{
  animation: lightSpeedIn .3s ease-in forwards;
}
@-webkit-keyframes lightSpeedIn{

from {
	-webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
	transform: translate3d(100%, 0, 0) skewX(-30deg);
	opacity: 0;
}
60% {
	-webkit-transform: skewX(20deg);
	transform: skewX(20deg);
	opacity: 1;
}
80% {
	-webkit-transform: skewX(-5deg);
	transform: skewX(-5deg);
	opacity: 1;
}
to {
	-webkit-transform: none;
	transform: none;
	opacity: 1;
}
}
 
.swiper-slide{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
