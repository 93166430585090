// 移动端适配
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import '@/assets/css/base.scss'
import 'swiper/css/swiper.min.css';
import { Init } from '@/assets/js/build' 
import App from './App.vue'
// import VConsole from 'vconsole';
Es6Promise.polyfill()
// new VConsole();

Init(App);
