<!--  -->
<template>
    <div id="btnGroup">
        <div>
            <img :src="$imgUrl+'/phoneWebsite/register.png'" @click="go(1)" class="img" alt="">
        </div>
        <div class="mt20">
            <img :src="$imgUrl+'/phoneWebsite/login.png'" @click="go(2)" class="img" alt="">
        </div>
        <div class="qykf" @click="openQy">
            <img src="https://qiyukf.nosdn.127.net/sdk/res/kefu/custom/1.png" alt="">
        </div>
    </div>

</template>

<script setup lang='ts'>
    const go =(flag:number)=>{
        let domain = 'https://www.wemew.com/'
        if(window.location.href.indexOf('test.wemew')!=-1||window.location.href.indexOf('192.168.')!=-1){
            domain = 'https://test.wemew.cn/'
        }
        if(flag==1){
           window.location.href= domain+'wemew/admin/wemewAdmin/index.html#/register'
        }else{
            window.location.href= domain+'adminLogin'
        }
    }
    const openQy = ()=>{
        ysf('open')
    }
</script>
<style scoped lang='scss'>
#btnGroup{
    position: absolute;
    right: 0;
    top: 1rem;
    z-index: 9;
    .img{
        width: 1.2rem;
    }
}
.qykf{
    background-color: #f96868;
    border-radius: 14px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.25);
    position: fixed;
    max-width: 30px;
    max-height: 120px;
    right: .2rem;
    bottom: 1.4rem;
    cursor: pointer;
    overflow: visible;
    filter: alpha(opacity=100);
    opacity: 1;
    z-index: 9990;
}
.qykf img{
    width: 28px;
    height: auto;
}

</style>
