<!--  -->
<template>
      <div id="container1">
            <div class="options-panel">
                <!-- <p>12211</p> -->
            <a v-for="(item,index) in list" @click="change(item)" :key="index" :class="chooseItem==item?'active':''">
                <img id="VFace" :src='`../assets/facemeshes/${item}.png`'  /></a>
            <!-- <a @click="change('猫咪面具')">
                <img id="VFace" src="../assets/facemeshes/猫咪面具.png" her /></a>
           
            <a @click="change('V字面具')">
                <img id="VFace" src="../assets/facemeshes/V字面具.png" her /></a>
            <a  @click="change('蓝蝴蝶')">
                <img id="LHDFace" src="../assets/facemeshes/蓝蝴蝶.png" /></a> -->
            <!-- <a href="arfacemasks.html?face=V字面具">
                <img id="VFace" src="./assets/facemeshes/V字面具.png" her /></a>
            <a href="arfacemasks.html?face=金属鬼脸面具羽化">
                <img id="JingShuFace" src="../assets/facemeshes/金属鬼脸面具羽化.png" /></a>
            <a href="arfacemasks.html?face=蓝蝴蝶">
                <img id="LHDFace" src="../assets/facemeshes/蓝蝴蝶.png" /></a>
            <a href="arfacemasks.html?face=绿色系面具">
                <img id="LSMJFace" src="../assets/facemeshes/绿色系面具.png" /></a>
            <a href="arfacemasks.html?face=派对面具">
                <img id="PDMJFace" src="../assets/facemeshes/派对面具.png" /></a>
            <a href="arfacemasks.html?face=铁面罩">
                <img id="TMZFace" src="../assets/facemeshes/铁面罩.png" /></a>
            <a href="arfacemasks.html?face=威尼斯狂欢面具羽化">
                <img id="WNSFace" src="./assets/facemeshes/威尼斯狂欢面具羽化.png" /></a> -->
            </div>
    </div>
    <div style="position: fixed; top: 2.7rem; right: -.3rem;z-index: 999;" @click="poster">
        <button class="btn">保存图片</button>
    </div>
    <div  style="position: fixed;top: 0;left: 0;z-index: 8;" id="img">
        
    </div>
    <div v-if="downUrl" class="imgBox" >
        <img :src="downUrl" width="100%" alt="">
    </div>


    <div >
        <canvas
        id="shortCut"
        width="750"
        height="1334"
        style="opacity: 0"
        ></canvas>
        <canvas
        id="canvas1"
        width="750"
        height="1334"
        style="opacity: 0"
        ></canvas>
    </div>
    
  </template>
  
  <script setup lang='ts'>
    import {nextTick,ref} from 'vue'
    import * as THREE from 'three';
    import html2canvas from '../assets/js/html2canvas.js'
    import { MindARThree } from '../assets/libs/mindar-face-three.prod.js';
    // var Jimp = require("jimp") 
    // import Jimp from "jimp"

    // import * as  from '../libs/es-module-shims.js'
    let params ={} as any
    let renderer
    let width=750
    let height = 1334
    let faceMesh
    let mindarThree
    let chooseItem = ref('蓝蝴蝶')
    let list=['京剧面具','猫咪面具','蓝蝴蝶','V字面具']
    nextTick(function(){
         mindarThree = new MindARThree({
            container: document.querySelector("#container1"),
        });
        width = document.documentElement.offsetWidth
        height = document.documentElement.offsetHeight
        console.log('width',width,height)
        let canvas1 = document.getElementById('canvas1')
        canvas1.width = width
        canvas1.height = height

        let cut = document.getElementById('shortCut')
        cut.width = width
        cut.height =height
        // const { renderer, scene, camera } = mindarThree;
         renderer =mindarThree.renderer
        const scene=mindarThree.scene,camera = mindarThree.camera
        var ambientLight = new THREE.AmbientLight(0xffffff);
        // const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.7);
        scene.add(ambientLight);
        if (params["face"] === undefined)
        var facetex = '../assets/facemeshes/威尼斯狂欢面具羽化.png';
        else
        var facetex = '../assets/facemeshes/' + params["face"] + '.png';
        //console.log("imgurl:" + facetex);
         faceMesh = mindarThree.addFaceMesh();
        const texture = new THREE.TextureLoader().load(facetex);
        faceMesh.material.map = texture;
        faceMesh.material.transparent = true;
        faceMesh.material.needsUpdate = true;
        scene.add(faceMesh);

        const start = async () => {
            await mindarThree.start();
            renderer.setAnimationLoop(() => {
                renderer.render(scene, camera);
            });
        }
        start();
    })
    const update =()=>{
        var facetex = '../assets/facemeshes/'+chooseItem.value+'.png';
        // faceMesh = mindarThree.addFaceMesh();
        const texture = new THREE.TextureLoader().load(facetex);
        faceMesh.material.map = texture;
        faceMesh.material.transparent = true;
        faceMesh.material.needsUpdate = true;
    }
   const change =(name)=>{
    chooseItem.value =name
        update()
        console.log('change',name)
    }
    let downUrl = ref('')
    function poster2(){
         html2canvas(document.getElementById('img'), {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                backgroundColor: null,
            }).then( (canvas:any) => {
               console.log('scuess')
                downUrl.value = canvas.toDataURL();
                // if (this.callBack){
                //     this.callBack(this.downUrl);
                // }
            });
    }
    function poster(){
        getPhoto()
       
    }
var imgList =[]
    // 获取人像照片
   const getPhoto = ()=> {
        try {
          let video = document.getElementById('video')
          let cut = document.getElementById('shortCut')
         
          let context2 = cut.getContext('2d')
          context2.drawImage(video, 0, 0, width, height)
          keepImg()
        } catch (error) {
            console.log(error)
        }
      }
     const convertCanvasToImage = (canvas:any)=> {
        let image = new Image()
        image.src = canvas.toDataURL('image/png')
        return image
      }
    const  keepImg = ()=> {
        //先保存完整的截图
        let cut = document.getElementById('shortCut')
        let context = cut.getContext('2d')
        //从完整截图里面，截取左侧294x345大小的图片，添加到canvas1里面
        let imgData = context.getImageData(0, 0, width, height)
        let canvas1 = document.getElementById('canvas1')
        let context1 = canvas1.getContext('2d')



        context1.putImageData(imgData, 0, 0)
        let img = document.getElementById('img')
        //把canvas1里面的294x345大小的图片保存
        let photoImg = document.createElement('img')
        photoImg.className = 'person'
        photoImg.src = convertCanvasToImage(canvas1).src
        img.appendChild(photoImg)
        keepImg1()
        console.log('保存截图',imgList.push(convertCanvasToImage(canvas1).src))
      }
      const  keepImg1 = ()=> {
        //先保存完整的截图
      let img = document.getElementById('img')
        //把canvas1里面的294x345大小的图片保存
        let photoImg = document.createElement('img')
        photoImg.className='iconimg'
        photoImg.style='width: 100%;height: 100%;position: absolute;top: 0;left: 0;'
        photoImg.src = renderer.domElement.toDataURL()
        img.appendChild(photoImg) 
        setTimeout(() => {
            poster2()
        }, 300);

      }
      

    
    
  </script>
  <style scoped lang='scss'>
  .active{
    img{
        border: 1px solid #ff4b69 !important;
    }
   
  }
  .btn{
    padding: .1rem .4rem .1rem .2rem;
    border: none;
    background-color: #ff4b69;
    color: #fff;
    font-size: .3rem;
    border-radius: .3rem;
  }
  .iconimg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  #img{
    img{
        width: 100%;
    }
  }
  .imgBox{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    img{
        width: 100%;
    }
    .iconimg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
  }
#container1 {
      width: 100vw;
      height: 100vh;
      position: absolute;
      overflow: hidden;
      z-index: 999;
      top: 0;left: 0;
    }

    .options-panel {

      position: fixed;
      top: 10px;
      left: 50%;
      display: flex;
      transform: translateX(-50%);
      z-index: 2;
    }

    .options-panel img {
      border: solid 1px;
        margin-left: 5px;
      width: 50px;
      height: 50px;
      object-fit: cover;
      cursor: pointer;
      border-radius: 5px;
    }
  </style>
  