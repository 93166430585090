import { Component, createApp,reactive } from 'vue';
import { getQueryString } from './utils'
import './rem'



const mode = import.meta.env.MODE
export const baseState = reactive({
    barId: getQueryString('barid'),
    testMode: getQueryString('testMode'),
    $imgUrl: '',
    $ossUrl: '',
    $domain: '',
    mode: '',
})
const isCDN = getQueryString('isCDN');


if (mode=='pro') {
    // 正式服
    baseState.$imgUrl = 'https://oss.wemew.com/wemew/wechat/appstatic'
    baseState.$domain = 'https://www.wemew.com/wemew/wechat'
    baseState.$ossUrl = 'https://oss.wemew.com/wemew/wechat/appstatic'
    if (isCDN == '1') {
        // 不走cnd
        baseState.$imgUrl = 'https://nocdn-oss.wemew.com/wemew/wechat/appstatic'
        baseState.$ossUrl = 'https://nocdn-oss.wemew.com/wemew/wechat/appstatic'
    }
    else {
        baseState.$imgUrl = 'https://oss.wemew.com/wemew/wechat/appstatic'
        baseState.$ossUrl = 'https://oss.wemew.com/wemew/wechat/appstatic'
    }

    (window as any).imgUrl = baseState.$imgUrl;
}else{
    // "开发环境"
    baseState.$imgUrl = 'https://test.wemew.cn/wemew/wechat/appstatic'
    baseState.$domain = 'https://test.wemew.cn/wemew/wechat'
    baseState.$ossUrl = 'https://test.wemew.cn/wemew/wechat/appstatic'
}

export let Init = async (App: Component) => {
    const app = createApp(App);

    app.config.globalProperties.$imgUrl = baseState.$imgUrl;
    app.config.globalProperties.$ossUrl = baseState.$ossUrl;
    app.config.globalProperties.$domain = baseState.$domain;

    app.mount('#app');
}



