<!--  -->
<template>
  <div id="product">
    <header>
        <img :src="$imgUrl+'/phoneWebsite/page11/title.png'" class="title" alt="">
    </header>
    <div class="container">
        <!-- <img :src="$imgUrl+'/phoneWebsite/page11/bg.png'" class="bg" alt="" /> -->
        <div class="container_info">
            <div class="top_box">
                <div class="logoItem" v-for="(item,index) in logoList" :key="index">
                    <img :src="$imgUrl+'/phoneWebsite/page11/logo/'+item+'.jpg'" class="patanerLogo" alt="">
                </div>
            </div>
           
        </div>
        <!-- <img :src="$imgUrl+'/phoneWebsite/page4/infoTxt.png'" class="infoTxt" alt="" /> -->
    </div>
  </div>
</template>

<script setup lang='ts'>
let logoList=[
    1,2,3,4,5,6,7,8,9,11,12,13,14,15,16,17,18,19,20,21,22,23,25,26,27
]
 const props= defineProps()
 const emits= defineEmits(['change'])
</script>
<style scoped lang='scss'>
#product{

    header{
        text-align: center;
        box-sizing: border-box;
        // padding: .rem 0;
        .title{
            width: 60%;
        }
    }
    .container{
        text-align: center;
        position: relative;
        
        .container_info{
            width: 90%;
            // position: absolute;
            background-color: #fff;
            // top: 50%;
            // left: 50%;
            // transform: translateX(-50%,-50%);
            text-align: center;
            margin: 0 auto;
            padding: .2rem;
            border-radius: .2rem;
            box-shadow: 1px 1px 1px #ccc;
            // padding: .3rem 0;
            .top_box{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                // border: 1px solid red;
                .logoItem{
                    margin-left: .1rem;
                    margin-top: .2rem;
                    box-shadow: 5px 5px 5px #ccc;
                    .patanerLogo{
                        width: 1.2rem;

                    }
                }
            }
            
        }
        .bg{
            width: 90%;
        }
        .infoTxt{
            width: 40%;
        }
    }
}
</style>
